<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div class="tc-page">
            <div class="tc-banner">
                <div class="tc-banner-image">
                    <img src="@/assets/images/about-display.jpg" alt="" />
                </div>
                <div class="tc-banner-details">
                    <div class="tc-banner-text">
                        <p class="text-1">Privacy Policy</p>
                    </div>
                </div>
            </div>
            <div class="content-box">
                <p>
                    Siloho takes your privacy very seriously. This privacy
                    policy (“Privacy Policy”) is intended to inform you about
                    the information that is collected from you when you visit
                    website and applications owned and operated by Siloho (the
                    “Platform”); how this information may be used and disclosed;
                    and how your information is protected. Siloho.co.in and is a
                    property of and is hosted and operated by UNELMA DESIGN LABS
                    PRIVATE LIMITED., a company incorporated under the Indian
                    Companies Act, 1956 and having its registered office at 2nd
                    FLOOR, Flat No. 13/666/13, TATO BUILDING, TONCA CARANZALEM,
                    TISWADI, North Goa, Goa, 403002
                </p>
            </div>
            <div class="content-box">
                <h3>I. Acceptance of Terms</h3>
                <p>
                    PLEASE READ THE PRIVACY POLICY THOROUGHLY AND CAREFULLY. The
                    terms and conditions set forth below through the Privacy
                    Policy constitute a legally-binding agreement between 2nd
                    FLOOR, Flat No. 13/666/13, TATO BUILDING, TONCA CARANZALEM,
                    TISWADI, North Goa, Goa, 403002. ("Siloho"), and you. This
                    policy contains provisions that define your limits, legal
                    rights and obligations with respect to your use of and
                    participation on the Platform, including the classified
                    advertisements, forums, various email functions and Internet
                    links, and all content and Siloho services available on the
                    Platform. The Privacy Policy is applicable to all users of
                    the Platform, including users who have access and/or
                    register on the Platform, and users who are also
                    contributors of content, information, private and public
                    messages, advertisements, and other materials or services on
                    the Platform.
                </p>
                <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p> -->
            </div>
            <!-- <div class="content-box">
            <h3>II. Contact us</h3>
            <p>
                Should you have any questions regarding this Privacy Policy,
                your privacy as it relates to the use of the Service, or the
                protection of the personal data we hold about you, we encourage
                you to primarily contact us through the support features within
                our games. Alternatively, you may contact<br />Name of the
                controller: Lorem ipsum dolor sit amet, <br />Address: Lorem
                ipsum dolor sit amet,<br />Email: Lorem ipsum dolor sit amet,
            </p>
        </div> -->
            <div class="content-box">
                <h3>II. Information We Collect</h3>
                <h4>Personal information</h4>
                <p>
                    “Personal information” is defined to include information
                    that may be used to readily identify or contact you such as:
                    name, address, PIN code, email address, phone number, order
                    details, user records etc. that is otherwise not publicly
                    available. When you sign up on the Platform we collect
                    personal information from (i) users who are interested in
                    availing our services, (ii) design partners who offer their
                    design services to Siloho customers (“Design Partners”), and
                    (iii) service partners offering their services (“Service
                    Partners”). Personal information collected from Design
                    Partners and Service Partners may be partially or completely
                    accessible to users on the Platform or those availing our
                    services. Personal information from users availing our
                    services is partially or completely available to relevant
                    Design Partner(s), Service Partner(s) and employee(s) of
                    Siloho on a need to know basis. All information disclosed by
                    you shall be deemed to be disclosed willingly and without
                    any coercion. No liability pertaining to the authenticity/
                    genuineness/ misrepresentation/ fraud/ negligence, etc. of
                    the information disclosed shall lie on Siloho nor will
                    Siloho in any way be responsible to verify any information
                    obtained from you.
                </p>
                <h4>Personal information from social networking sites</h4>
                <p>
                    You may log into our Platform through your Facebook or
                    Google account. If you do so, you must enter the email
                    address and password that you use for your account. We will
                    ask that you grant us permission to access and collect your
                    Facebook basic information (this includes your name, profile
                    picture, gender, networks, user IDs, list of friends, date
                    of birth, email address, and any other information you have
                    set to public on your Facebook account). If you allow us to
                    have access to this information, then we will have access to
                    this information even if you have chosen not to make that
                    information public. We store the information that we receive
                    from Facebook with other information that we collect from
                    you or receive about you. Facebook controls the information
                    it collects from you. For information about how Facebook may
                    use and disclose your information, including any information
                    you make public, please consult Facebook's privacy policy.
                    We have no control over how any third party site uses or
                    discloses the personal information it collects about you.
                </p>
                <h4>Calls and text messages</h4>
                <p>
                    Siloho enables users to call and chat with the Design
                    Partners, Service Partners through the Platform. As
                    otherwise described in this policy, your contact information
                    may be accessible to a Design Partner or Service Partner for
                    the sole purpose of providing services. In addition,
                    regarding the calls or chats, we collect information
                    including the date and time of the call/chat message(s), and
                    the content of the calls and chat messages in order to
                    improve our customer service and quality of services. Siloho
                    may also use this information for resolving disputes between
                    you and Design Partners or Service Partners or other users,
                    or for safety and security purposes, and for analytics.
                </p>
                <h4>Age</h4>
                <p>
                    We respect children's privacy. We do not knowingly or
                    intentionally collect personal information from children
                    under age 13. By accessing and/or registering on the
                    Platform, you have represented and warranted that you are
                    either 18 years of age or using the Platform with the
                    supervision of a parent or guardian. If you are under the
                    age of 13, please do not submit any personal information to
                    us, and rely on a parent or guardian to assist you.
                </p>
                <h4>Refer a friend</h4>
                <p>
                    Siloho offers the opportunity to any user to share details
                    of anyone who they think may be interested in Siloho or our
                    services. This information is recorded and used only to
                    communicate with the referee using mediums including phone
                    calls and email, based on the details provided to Siloho. We
                    believe that it is within the purview of the referrer to
                    seek authorization to share the personal information of the
                    referee. When the information is shared with Siloho, it is
                    deemed that the authorization has already been obtained by
                    the referrer.
                </p>
                <h4>Communications</h4>
                <p>
                    During your interaction with Siloho, you may also
                    participate in polls, surveys and feedback and personal
                    information including call logs during such interactions
                    will be collected. Further, when you communicate with us
                    (via email, phone, texts, through the Platform or
                    otherwise), we may maintain a record of your communication.
                    When you use the Platform to communicate with others, we may
                    collect and maintain a copy of those communications, in
                    accordance with applicable law. Such information obtained
                    from a user is gathered and utilized to ensure a greater
                    experience for users and partners.
                </p>
                <h4>Payment, billing and financial information</h4>
                <p>
                    When you create an account with Siloho and start (i)
                    availing services from us, our Design Partners and or
                    Service Partners (ii) offer your services as a Design
                    Partner or Service Partner, you may be required to disclose
                    information such as bank details, PAN card, Aadhaar card,
                    credit card details, etc. in order for us to process your
                    request. Additionally, Siloho may also collect information
                    related to the manner and mode of payments usually adopted
                    by you on the Platform, including details of your bank
                    account, credit card and debit card. The financial
                    information collected may vary in accordance to your
                    interaction with the Platform and our Partners.
                </p>
                <h4>Device and location information</h4>
                <p>
                    We collect information from your devices (computers, mobile
                    phones, tablets, etc.), including information about how you
                    interact with our services and information that allows us to
                    recognize and associate your activity across devices and
                    services. This information includes device specific
                    identifiers and information such as location, IP address,
                    cookie information, mobile device and advertising
                    identifiers, browser version, operating system type and
                    version, mobile network information, device settings, and
                    software data. We may recognize your devices to provide you
                    with personalized experiences and advertising across the
                    devices you use. In addition, the information may also
                    include your location.
                </p>
                <h4>Information from cookies and other tracking methods</h4>
                <p>
                    This Platform employs cookies, web beacons and other
                    tracking technologies to speed your navigation of the
                    Platform, recognize you and your access privileges, and
                    track your Platform usage. These help us in Platform
                    analytics to give you a better experience. The same may be
                    used to evaluate the success of our advertising campaigns.
                    This includes information, but is not limited to, tracking
                    how frequently you visit our Platform and use our services,
                    time spent on the Platform, pages visited, links clicked,
                    etc. We may use third-party advertising companies to help us
                    tailor site content to users or to serve ads on our behalf.
                    These companies may employ cookies and web beacons to
                    measure advertising effectiveness (such as which web pages
                    are visited or what products are purchased and in what
                    amount). Any information that these third parties collect
                    via cookies and web beacons is not linked to any personal
                    information collected by us.
                </p>
                <h4>Information from other sources</h4>
                <p>These may include:</p>
                <ul>
                    <li>Feedback including surveys and ratings</li>
                    <li>
                        Siloho business partners through which you create or
                        access your Siloho account, such as payment providers,
                        social media services, or apps or websites who use
                        Siloho's
                    </li>
                    <li>APIs or whose API Siloho uses</li>
                    <li>Financial services providers</li>
                    <li>Marketing service providers</li>
                </ul>
                <p>
                    Siloho may combine the information collected from these
                    sources with other information in its possession. By
                    choosing to provide any and all of the aforesaid information
                    to Us, you are giving Siloho permission to use and/or store
                    such information consistent with this Privacy Policy.
                    Further, Siloho shall be entitled to retain your Personal
                    information for such duration as may be required for the
                    purposes specified hereunder and shall be used us only in
                    accordance with this Privacy Policy.
                </p>
            </div>
            <div class="content-box">
                <h3>III. How do we use this information</h3>
                <!-- <ul>
                <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                </li>
                <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                </li>
                <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat
                </li>
                <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat
                </li>
            </ul> -->
                <h4>Internal use</h4>
                <p>
                    Siloho uses the information we collect to provide,
                    personalize, maintain and improve our products and services,
                    including processing your order and providing you with
                    end-to-end interior design and execution services.
                    Additionally, the information may be used to improve our
                    Platform's content and layout, to improve our outreach and
                    for our own marketing efforts (including marketing our
                    services and products to you), and to determine general
                    marketplace information about visitors to this Platform. The
                    information may be used for product development, testing and
                    improvement of existing services and products. This allows
                    us to develop new product features and also facilitate
                    financial solutions and insurance that are connected to
                    Siloho services.
                </p>
                <h4>Providing services and features</h4>
                <p>
                    Siloho uses the information we collect to provide,
                    personalize, maintain and improve our products and services.
                    This includes:
                </p>
                <ul>
                    <li>
                        Matching you with a suitable Design and Service partner
                        to offer our design and execution services.
                    </li>
                    <li>
                        Facilitating in-account activities during your project
                        such as in-website chat.
                    </li>
                    <li>Processing and placing orders for your project.</li>
                    <li>Process or facilitate payment for your orders.</li>
                    <li>
                        Offer, obtain, provide or facilitate insurance or
                        financing solutions in connection with our services
                    </li>
                    <li>
                        Perform internal operations necessary to provide our
                        services, including to troubleshoot software bugs and
                        operational problems, to conduct data analysis, testing,
                        and research, and to monitor and analyze usage and
                        activity trends
                    </li>
                </ul>
                <p>
                    We confirm that the information that we collect from you may
                    be used in aggregate form in various ways to optimize and
                    improve Siloho’s services, understand usage trends, analyse
                    behavioral patterns, determine the effectiveness and
                    usefulness of services and/or communications shared with
                    you. While such information may be based on information
                    about you, it will not identify you personally.
                </p>
                <h4>Customer support</h4>
                <p>
                    Siloho uses information collected about you (including
                    recordings of customer support calls after notice to you and
                    with your consent) to assist you when you contact our
                    customer support services, including to:
                </p>
                <ul>
                    <li>
                        Direct your questions to the appropriate customer
                        support person
                    </li>
                    <li>Investigate and address your concerns</li>
                    <li>Monitor and improve our customer support response</li>
                </ul>
                <h4>Communications with You</h4>
                <p>
                    Siloho uses the information shared by you to communicate
                    with you, including for the purpose of project updates,
                    customer experience surveys, promotional offers and account
                    information. The mode of communication includes emails,
                    electronic newsletters, invitations, polls and surveys.
                </p>
                <h4>Communications among users and with Siloho partners</h4>
                <p>
                    To offer our services, or to facilitate Siloho Partners to
                    offer their services, personal information, partially or
                    fully, is made available to users of Siloho. As an example,
                    when you sign up to receive services from Siloho, your
                    contact information including phone number and email is
                    shared with a Design Partner and/or Service Partner to offer
                    his/her service for your project. Sometimes, the users are
                    allowed to contact each other for marketing purposes as
                    well.
                </p>
                <!-- </div> -->
                <div class="">
                    <h3>IV. How we share this information</h3>
                    <p>
                        Siloho does not sell or transfer your Personal
                        information (including your phone number, email address)
                        to third-parties for the use of their own marketing.
                    </p>
                    <h4>Third party ad networks</h4>
                    <p>
                        Third party ad networks display advertisements on other
                        websites based on your visits to the Platform as well as
                        other websites. These ad networks, sponsors, advertisers
                        and traffic measurement services, employ technologies
                        such as cookies, JavaScript, web beacons, and others to
                        capture information governed by their own specific
                        privacy policy(ies). We participate in these networks to
                        enable us and the third parties to display
                        advertisements for products and services that might
                        interest you.
                    </p>
                    <h4>Analytics and reports</h4>
                    <p>
                        The information collected is used to create analytic
                        reports for both internal and external partners,
                        including business partners and the public regarding the
                        use of and trends within our services and ads. For the
                        purpose of studying and sharing trends, we may share
                        aggregated or pseudonymous information (including
                        demographic information), that is limited to
                        non-personally identifiable information, with partners,
                        measurement analytics, apps, third party ad networks, or
                        media.
                    </p>
                    <h4>Public Posts</h4>
                    <p>
                        You (including Design Partners and Service Partners) may
                        provide your feedback, reviews, comments, content, other
                        information etc. on the Platform (“Posts”). Your Posts
                        shall have to comply with the conditions relating to
                        Posts as mentioned in the Terms of Use. Siloho retains
                        an unconditional right to remove and delete any Post or
                        such part of the Post that, in the opinion of Siloho,
                        does not comply with the conditions in the Terms of Use.
                        [All Posts shall be publicly accessible and visible to
                        all Users of the Platform.] Siloho reserves the right to
                        use, reproduce and share Your Posts for any purpose. If
                        you delete Your Posts from the Platform, copies of such
                        Posts may remain viewable in archived pages, or such
                        Posts may have been copied or stored by other users.
                    </p>
                    <h4>Information disclosed at your request</h4>
                    <p>
                        To avail Siloho service(s), you may give us consent to
                        share such personal information, as may be required,
                        with our Design Partners/ Service Partners offering
                        those services. In the event of such a request, Siloho
                        shares only the information necessary for them to offer
                        the specific service. All Design Partners and Service
                        Partners are required to follow appropriate
                        confidentiality and safety measures to protect your
                        data.
                    </p>
                    <h4>New ownership or bankruptcy</h4>
                    <p>
                        If the ownership or control of all or part of Siloho
                        changes as a result of a merger, acquisition or sale of
                        assets, we may transfer your information to the new
                        owner, a third party entity (a "Successor") In addition,
                        in the event of Siloho's bankruptcy, reorganization,
                        receivership, or assignment for the benefit of
                        creditors, or the application or laws or equitable
                        principles affecting creditors' rights generally, Siloho
                        may not be able to control how your information is
                        transferred, used, or treated and reserves the right to
                        transfer the information we have collected from the
                        users of the Platform and/or services to non-affiliated
                        third parties in such event.
                    </p>
                    <h4>Legal investigations</h4>
                    <p>
                        We may investigate and disclose information from or
                        about you, as permitted by applicable law, if we believe
                        that such investigation or disclosure is (a) necessary
                        to comply with legal process and law enforcement
                        instructions and orders, such as a search warrant,
                        order, statute, judicial proceeding, or other legal
                        process served on us; (b) helpful to prevent,
                        investigate, or identify possible wrongdoing in
                        connection with the Platform; or (c) helpful to protect
                        our rights, reputation, property, or that of our users,
                        subsidiaries, or the public. In such an event Siloho
                        shall be under no obligation to inform you or seek your
                        approval or consent.
                    </p>
                </div>
                <div class="">
                    <h3>V. Data Security</h3>
                    <h4>Online and during your interactions with Siloho</h4>
                    <p>
                        Siloho shall take reasonable steps to help protect your
                        rights of privacy and your information (personal or
                        otherwise) in an effort to prevent loss, misuse,
                        unauthorized access, disclosure, alteration, or
                        destruction of such information, in compliance with the
                        applicable laws. This Platform uses physical,
                        electronic, and administrative procedures to safeguard
                        the confidentiality of your information. SSL encryption
                        is used to protect your personal information online, and
                        we also take several steps to protect your personal
                        information in our facilities. Access to your personal
                        information is restricted. Only employees and Partners
                        (Design & Service) who need access to your personal
                        information to perform a specific job or provide a
                        service are granted access to your personal information.
                        Finally, we rely on third-party service providers for
                        the physical security of some of our computer hardware.
                        We believe that their security procedures are adequate.
                        For example, when you visit our Platform you access
                        servers that are kept in a secure physical environment,
                        behind a locked cage and an electronic firewall. Siloho
                        assumes no liability or responsibility for disclosure of
                        your information due to errors in transmission,
                        unauthorized third party access, or other causes beyond
                        its control. At times we may retain third parties to
                        analyze data we collect on our Platform, and to
                        administer the services provided (such as shipping, web
                        hosting, etc. as described above), solely to help us
                        provide and improve our products, services and Platform.
                        These suppliers and other third parties are not
                        authorized by us to use your personal information in any
                        other way. We may have to use personal information to
                        investigate complaints and protect Siloho and its
                        members, in compliance with the law, your Membership
                        Agreement and Siloho policies. While we use
                        industry-standard precautions to safeguard your personal
                        information, we cannot guarantee complete security. 100%
                        complete security does not presently exist anywhere
                        online or offline. In the unlikely event that we believe
                        that the security of your personal information in our
                        possession or control may have been compromised, we may
                        seek to notify you of that development. If a
                        notification is appropriate, we would endeavor to do so
                        as promptly as possible under the circumstances, and, to
                        the extent we have your email address, we may notify you
                        by email.
                    </p>
                </div>
                <div class="">
                    <h3>VI. Links to external websites</h3>
                    <h4>Information not controlled by Siloho</h4>
                    <p>
                        This Platform may contain links to other websites or
                        resources over which Siloho does not have any control.
                        Such links do not constitute an endorsement by Siloho of
                        those external websites. You acknowledge that Siloho is
                        providing these links to you only as a convenience, and
                        further agree that Siloho is not responsible for the
                        content of such external websites. We are not
                        responsible for the protection and privacy of any
                        information which you provide while visiting such
                        external websites and such sites are not governed by
                        this Policy. Your use of any external website is subject
                        to the terms of use and privacy policy located on the
                        linked to external website.
                    </p>
                </div>
                <div class="">
                    <h3>VII. Choice and Transparency</h3>
                    <h4>Accessing and Correcting Your Information</h4>
                    <p>
                        You can update the details associated with your account
                        by visiting My account and accessing the account
                        settings on the Platform. You can also look up your
                        payment information, details about your project(s),
                        services, etc. You may also update the information
                        yourself, if allowed on the Platform or request updation
                        of your information by contacting Siloho at
                        care@Siloho.com
                    </p>
                    <h4>Marketing Opt-Outs</h4>
                    <p>
                        We may send promotional or informational emails to
                        you..You may opt out of receiving promotional emails
                        from Siloho the opt-out instructions contained in the
                        email. Please note that if you opt out, we may still
                        send you non-promotional messages, such as meeting
                        invites, project progress, etc.
                    </p>
                    <h4>Cookie choices</h4>
                    <p>
                        Most web browsers are set to accept cookies by default.
                        If you prefer, you can usually choose to set your
                        browser to remove or reject cookies. If you choose to
                        remove or reject cookies, this could affect certain
                        features of the Platform and/or services.
                    </p>
                    <h4>Withdrawal of consent</h4>
                    <p>
                        You may choose to withdraw your consent provided
                        hereunder at any point in time. Such withdrawal of the
                        consent must be sent in writing to privacy@Siloho.com.
                        In case you do not provide your consent or later
                        withdraw your consent, we request you not to access the
                        Platform and use the services and also reserve the right
                        to not provide you any services on the Platform. In such
                        a scenario, Siloho may delete your information (personal
                        or otherwise) or de-identify it so that it is anonymous
                        and not attributable to you.
                    </p>
                </div>
                <div class="">
                    <h3>VIII. Amendments</h3>
                    <p>
                        Siloho retains an unconditional right to modify or amend
                        this Privacy Policy without any requirement to notify
                        you of the same. You can determine when this Privacy
                        Policy was last modified by referring to the “Last
                        Updated” legend above. It shall be your responsibility
                        to check this Privacy Policy periodically for changes.
                        Your continued use of the Platform following the posting
                        of changes to this Privacy Policy on the Platform, will
                        constitute your consent and acceptance of those changes.
                    </p>
                </div>
                <div class="">
                    <h3>IX. Grievances and Disputes</h3>
                    <h4>Grievances</h4>
                    <p>
                        In the event You have any grievances relating to the
                        Privacy Policy, please inform Siloho within 24 hours of
                        occurrence of the instance from which the grievance has
                        arisen, by writing an email to the Grievance Officer at
                        privacy@Siloho.co.in
                    </p>
                    <h4>Dispute resolution</h4>
                    <p>
                        Any dispute, controversy or claim directly or indirectly
                        caused by, arising out of or relating to this Privacy
                        Policy will be governed by the laws of India and will be
                        referred to confidential, mandatory and binding
                        arbitration in Panjim, Goa, India. The arbitration will
                        be conducted on an expedited basis before a single
                        arbitrator appointed by Siloho in accordance with the
                        provisions of the Indian Arbitration and Conciliation
                        Act, 1996. The arbitrator's award shall be substantiated
                        in writing and will be final and binding on you and
                        Siloho. Subject to the above, you agree to submit
                        yourself to the exclusive jurisdiction of the Courts in
                        Panjim,Goa, India.
                    </p>
                </div>
                <!-- <div class="last-updated">
            <span class="last-updated-bold">Last updated</span> MAY 10, 2021
        </div> -->
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
// import DisplayBanner from 'componentsv2/DisplayBanner'
import Footer from 'componentsv2/Footer'
import Loader from 'componentsv2/Loader'
import loaderHandler from 'mixins/loader'

export default {
    name: 'TCPage',
    mixins: [loaderHandler],
    components: {
        // DisplayBanner,
        Footer,
        Loader,
    },
    mounted() {
        setTimeout(() => {
            this.loaderShow = false
        }, 1000)
    },
}
</script>

<style lang="scss" scoped>
@import './TCPage.scss';
</style>
